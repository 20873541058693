import React from 'react';
import { Helmet } from 'react-helmet';
import { DOCUMENTS } from '../../const/documents';

interface SEOProps {
  description?: string;
  meta?: any[];
  keywords?: string[];
  title: string;
}

const SEO: React.FC<SEOProps> = (props) => {
  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={props.title}
      link={[
        {
          rel: 'icon',
          type: 'image/png',
          href: DOCUMENTS.logo,
        },
      ]}
      meta={[
        {
          name: `description`,
          content: props.description || '',
        },
        {
          property: `og:title`,
          content: props.title,
        },
        {
          property: `og:description`,
          content: props.description || '',
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: DOCUMENTS.logo,
        },
        {
          name: `twitter:card`,
          content: DOCUMENTS.logo,
        },
        {
          name: `twitter:creator`,
          content: 'Bó',
        },
        {
          name: `twitter:title`,
          content: props.title,
        },
        {
          name: `twitter:description`,
          content: props.description || '',
        },
      ]
        .concat(
          props.keywords && props.keywords.length > 0
            ? {
                name: `keywords`,
                content: props.keywords.join(`, `),
              }
            : []
        )
        .concat(props.meta || [])}
    />
  );
};

export default SEO;
