import styled from 'styled-components';
import { colors, media, dimensions, fontSizes, spacing } from './theme';

export const BoldText = styled.p<{ marginTop?: boolean }>`
  margin-top: ${(props) => props.marginTop && `50px`};
  font-family: GilroyBold;
  color: ${colors.darkGrey};
`;

export const LineBreak = styled.hr`
  margin-top: 30px;
  border-width: 0.5px;
`;

export const Wrapper = styled.div`
  flex-direction: column-reverse;
  max-width: ${dimensions.maxWidth};
  padding-top: 0;
  display: flex;
  ${media.tablet`
    flex-direction: row;
    padding-top: ${dimensions.headerHeight};    
    padding-bottom: ${spacing.forty};
  `}
`;

export const AltWrapper = styled.div`
  padding: ${spacing.twenty};
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  ${media.desktop`
    padding: 0;
    padding-top: ${dimensions.headerHeight};
  `}
`;

export const BlogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${spacing.thirty};
`;

export const Title = styled.h1`
  word-wrap: break-word;
  line-height: 100%;
  font-size: ${fontSizes.large};
  width: 100%;
  ${media.desktop`
    font-size: ${fontSizes.larger};
    width: 80%
  `}
`;

export const TileWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: ${dimensions.blogWrapperWidth};
  flex-wrap: wrap;
`;

export const ArticleWrapper = styled.div`
  width: 100%;
  background-color: ${colors.backgroundGrey};
  display: flex;
  justify-content: center;
  padding-top: 150px;
  padding-bottom: ${spacing.thirty};
`;

export const TextContainer = styled.div`
  width: 90%;
  padding-bottom: 40px;
  padding-left: 10vw;
  ${media.tablet`
    width: 60%;
  `}
`;

export const ImageContainer = styled.div`
  padding: 50px;
  box-sizing: border-box;
  ${media.tablet`
    width: 50%;
  `}
`;

export const Link = styled.a`
  color: ${colors.lightText};
`;

export const Content = styled.div`
  margin: auto;
`;

export const TableCell = styled.td<{ header?: boolean }>`
  border: 1px solid ${colors.lightText};
  padding: 8px;
  ${(props) => props.header && `background-color: ${colors.brandYellow}`}
`;

export const ImageContent = styled.div`
  display: block;
  margin: auto;
  width: 60%;
  margin-top: 0vw;
  max-width: 300px;
  max-height: 300px;
  ${media.tablet`
    margin-top: 5vw;
  `}
`;

export const CatWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  margin-top: ${spacing.sixty};
`;

export const AltArticleWrapper = styled(ArticleWrapper)`
  background-color: ${colors.white};
  padding-top: ${spacing.thirty};
  min-height: 400px;
`;

export const Download = styled.a`
  margin-top: ${spacing.twenty};
  display: block;
`;
