import React, { useState, useLayoutEffect } from 'react';
import { paths } from '../../const/paths';
import { Wrapper, LinkWrapper, Logo, StyledLink, LogoWrapper } from './styles';

const Header = () => {
  let lastScroll: number = 0;
  const [isVisible, setIsVisible] = useState(true);
  const [isSmallHeader, setIsSmallHeader] = useState(false);

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const currentScroll: number = Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop
    );
    const isTop = currentScroll <= 0;

    if (currentScroll >= lastScroll && lastScroll > 150) {
      // Scrolling down
      setIsSmallHeader(true);
      setIsVisible(false);
    } else {
      // Scrolling up
      isTop && setIsSmallHeader(false);
      setIsVisible(true);
    }

    // Always resets to big, visible header when switching screens
    if (isTop) {
      setIsVisible(true);
      setIsSmallHeader(false);
    }

    // Protects against negative scrolling
    lastScroll = isTop ? 0 : currentScroll;
  };

  const onMouseOver = () => {
    const element = document.querySelector('.active');
    if (element) {
      element.classList.replace('active', 'inactive');
    }
  };

  const onMouseLeave = () => {
    const element = document.querySelector('.inactive');
    if (element) {
      element.classList.replace('inactive', 'active');
    }
  };

  return (
    <Wrapper isVisible={isVisible}>
      <LinkWrapper onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
        <LogoWrapper to={paths.home}>
          <Logo isSmallHeader={isSmallHeader} />
        </LogoWrapper>
        <StyledLink to={paths.home} activeClassName="active">
          About
        </StyledLink>
        <StyledLink to={paths.blog} activeClassName="active">
          Blog
        </StyledLink>
        <StyledLink to={paths.faqs} activeClassName="active">
          FAQs
        </StyledLink>
        <StyledLink to={paths.contact} activeClassName="active">
          Contact
        </StyledLink>
      </LinkWrapper>
    </Wrapper>
  );
};

export default Header;
