export const titles = {
  home: 'Bó | Banking App | Do Money Better',
  contact: 'Contact Us | Bó',
  privacy: 'Privacy Notice | Bó',
  cookies: 'Cookie Policy | Bó',
  faqs: 'FAQs | Bó',
  fscs: 'FSCS Protection | Bó',
  help: 'Help | Bó',
  terms: 'Terms & Conditions | Bó',
  blog: 'Blog | Bó',
  websiteTerms: 'Website Terms & Conditions | Bó',
  unboxingCompetition:
    'Unboxing Photograph Competition Terms and Conditions | Bó',
  socialMedia: 'Social Media Rules | Bó',
};

export const descriptions = {
  home:
    'Bó is the banking app that helps you spend less and save more. Set a spending budget and keep track of your spending. Download now for free!',
  contact:
    "Have a question? We're here to help. Contact us by tapping the Support tab in the Bó app or email our friendly Support team at bosupport@natwest.com",
  privacy: "Read Bó's Privacy Notice",
  cookies: "Read Bó's Cookie Policy",
  faqs:
    'Need help using Bó? Check out our frequently asked questions or contact our friendly Support team',
  fscs:
    "Bó is protected by The Financial Services Compensation Scheme ('FSCS'). Find out more here.",
  help: "Need help? We're here to support you. Get in touch today!",
  terms: "Read Bó's Terms and Conditions",
  blog: 'Stay up to date with the latest Bó news',
  websiteTerms: "Read Bo's website Terms and Conditions",
};

export const altTags = {
  cardAndPhone: 'Bó banking app set up account',
  pug: 'pug',
  smilingGirl: 'lady in hoodie',
  cardPicture: 'Boó banking app card',
  clappyHands: 'clappy hands blue graphic',
  creps: 'trainers',
  natwest: 'natwest logo',
  badges: 'Bó bages',
  collage: 'collage',
  pen: 'Bó pen blue graphic',
  tea: 'Bó cup blue graphic',
  hola: 'Bó hola blue graphic',
  letter: 'Bó form blue graphic',
  vault: 'Bó bank blue graphic',
};
