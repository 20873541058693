import styled from 'styled-components';
import { Link } from 'gatsby';
import {
  media,
  colors,
  fontSizes,
  dimensions,
  spacing
} from '../../styles/theme';

export const Button = styled.button`
  border: none;
  background-color: transparent;
  border-left: 1px solid ${colors.backgroundGrey};
  height: 100%;
  width: ${spacing.sixty};
  padding-left: 22px;
  &:hover {
    opacity: 0.5;
  }
  ${media.desktop`
    display: none;
  `}
`;

export const DrawerWrapper = styled.div<{ isVisible: boolean }>`
  transition: 0.3s ease-in-out;
  position: fixed;
  top: ${dimensions.mobileHeaderHeight};
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translateX(${props => (props.isVisible ? '0%' : '-100%')});
  background-color: ${colors.white};
  z-index: 1;
  ${media.desktop`
    display: none;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  padding-right: ${spacing.thirty};
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: ${fontSizes.link};
  font-family: 'GilroyBold';
  margin-top: ${spacing.thirty};
  &:hover {
    opacity: 0.5;
  }
`;

export const SocialMediaWrapper = styled.div`
  display: flex;
  width: 45%;
  justify-content: space-between;
  padding-bottom: ${spacing.sixty};
  -webkit-align-self: center;
  align-self: center;
`;

export const SocialLink = styled.a`
  &:hover {
    opacity: 0.5;
  }
`;
