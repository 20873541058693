import React from 'react';
import { Helmet } from 'react-helmet';
import { config } from '../../config/config';
import { OneTrustStyles } from './styles';

export const OneTrust = () => {
  return (
    <>
      <OneTrustStyles />
      <Helmet>
        <script
          type="text/javascript"
          src={`https://cdn.cookielaw.org/consent/${config.oneTrustKey}/OtAutoBlock.js`}
        />
        <script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          data-document-language="true"
          type="text/javascript"
          data-domain-script={config.oneTrustKey}
        />
        <script type="text/javascript">function OptanonWrapper() {}</script>
      </Helmet>
    </>
  );
};

OneTrust.displayName = 'OneTrust';
