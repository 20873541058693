export const DOCUMENTS = {
  terms: {
    v1_1:
      'https://prismic-io.s3.amazonaws.com/wearebo/f0a05889-dd39-44e3-acfd-e3b94517f040_bo_account_terms_+v1_1_20191121.pdf',
  },
  fee_information: {
    v1:
      'https://prismic-io.s3.amazonaws.com/wearebo/beb2fe3c-135d-4208-bb06-1905af429387_bo_fee_information_+document.pdf',
  },
  glossary_of_terms: {
    v1:
      'https://prismic-io.s3.amazonaws.com/wearebo/a2e44b30-f563-4040-8294-e961e4bf3765_glossary_of_integrated+_terms.pdf',
  },
  FSCS: {
    v1:
      'https://prismic-io.s3.amazonaws.com/wearebo/4ebf8be7-5802-4cf2-a8f1-63fd2112b3c6_FSCS_Information_Sheet.pdf',
  },
  logo:
    'https://images.prismic.io/wearebo/f9341c41-d00a-445c-a9d3-1882cd7aec1a_logo.png?auto=compress,format',
};
