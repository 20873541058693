import styled from 'styled-components';
import { Link } from 'gatsby';
import {
  colors,
  dimensions,
  fontSizes,
  spacing,
  media
} from '../../styles/theme';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${spacing.forty};
  width: 100%;
  background-color: ${colors.darkGrey};
`;

export const Content = styled.div`
  max-width: ${dimensions.maxWidth};
`;

export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${spacing.thirty};
`;

export const LinkGroup = styled.div`
  margin-right: 5vw;
  display: none;
  flex-direction: column;
  position: relative;
  ${media.tablet`
    display: flex;
  `}
`;

export const MobileLinkGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${media.tablet`
    display: none;
  `}
`;

export const Text = styled.p`
  color: ${colors.white};
  font-size: ${fontSizes.small};
  margin-top: 0;
  margin-bottom: ${spacing.twenty};
`;

export const WhiteLink = styled.a`
  color: ${colors.white};
`;

export const StyledLink = styled(Link)`
  display: block;
  color: ${colors.white};
  text-decoration: none;
  line-height: 150%;
  font-size: ${fontSizes.normal};
  margin-bottom: ${spacing.base};
  &:hover {
    color: ${colors.brandYellow};
  }
`;

export const ExternalLink = styled.a`
  color: ${colors.white};
  line-height: 150%;
  text-decoration: none;
  font-size: ${fontSizes.normal};
  margin-bottom: ${spacing.base};
  &:hover {
    color: ${colors.brandYellow};
  }
`;

export const UpButtonWrapper = styled.div`
  position: absolute;
  display: none;
  right: ${spacing.forty};
  ${media.desktop`
    display: block;
  `}
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;

export const SocialLinkWrapper = styled.div`
  display: flex;
`;

export const SocialLink = styled.a`
  &:hover {
    opacity: 0.5;
  }
  margin-right: ${spacing.double};
  margin-bottom: ${spacing.base};
`;

export const activeStyle = {
  color: colors.brandYellow,
  fill: colors.brandYellow
};
