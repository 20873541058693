import React from 'react';
import { paths } from '../../const/paths';
import {
  Wrapper,
  WhiteLink,
  Text,
  LinkWrapper,
  Content,
  StyledLink,
  LinkGroup,
  MobileLinkGroup,
  SocialLink,
  SocialLinkWrapper,
  activeStyle,
  UpButtonWrapper,
} from './styles';
import Up from '../../assets/svgs/up-button-white.svg';
import Facebook from '../../assets/svgs/facebook-white.svg';
import Instah from '../../assets/svgs/instagram-white.svg';
import LinkedIn from '../../assets/svgs/linked-in-white.svg';

const Footer = () => {
  const onClick = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Wrapper>
      <Content>
        <UpButtonWrapper onClick={onClick}>
          <Up />
        </UpButtonWrapper>
        <LinkWrapper>
          <LinkGroup>
            <StyledLink to={paths.home} activeStyle={activeStyle}>
              Home
            </StyledLink>
            <StyledLink to={paths.blog} activeStyle={activeStyle}>
              Blog
            </StyledLink>
            <StyledLink to={paths.faqs} activeStyle={activeStyle}>
              FAQs
            </StyledLink>
            <StyledLink to={paths.contact} activeStyle={activeStyle}>
              Contact us
            </StyledLink>
          </LinkGroup>
          <LinkGroup>
            <StyledLink to={paths.websiteTerms}>
              Website Terms & Conditions
            </StyledLink>
            <StyledLink to={paths.terms} activeStyle={activeStyle}>
              Terms & Conditions
            </StyledLink>
            <StyledLink to={paths.fscs} activeStyle={activeStyle}>
              FSCS protection
            </StyledLink>
            <StyledLink to={paths.privacyFull} activeStyle={activeStyle}>
              Privacy Notice
            </StyledLink>
            <StyledLink to={paths.cookies} activeStyle={activeStyle}>
              Cookies policy
            </StyledLink>
          </LinkGroup>
          <LinkGroup>
            <StyledLink to={paths.emergencyAccess} activeStyle={activeStyle}>
              Lost your phone and card?
            </StyledLink>
          </LinkGroup>
          <MobileLinkGroup>
            <StyledLink to={paths.home} activeStyle={activeStyle}>
              Home
            </StyledLink>
            <StyledLink to={paths.blog} activeStyle={activeStyle}>
              Blog
            </StyledLink>
            <StyledLink to={paths.faqs} activeStyle={activeStyle}>
              FAQs
            </StyledLink>
            <StyledLink to={paths.contact} activeStyle={activeStyle}>
              Contact us
            </StyledLink>
            <StyledLink to={paths.websiteTerms}>
              Website Terms & Conditions
            </StyledLink>
            <StyledLink to={paths.terms} activeStyle={activeStyle}>
              Terms & Conditions
            </StyledLink>
            <StyledLink to={paths.fscs} activeStyle={activeStyle}>
              FSCS protection
            </StyledLink>
            <StyledLink to={paths.privacyFull} activeStyle={activeStyle}>
              Privacy Notice
            </StyledLink>
            <StyledLink to={paths.cookies} activeStyle={activeStyle}>
              Cookies policy
            </StyledLink>
            <StyledLink to={paths.emergencyAccess} activeStyle={activeStyle}>
              Lost your phone and card?
            </StyledLink>
          </MobileLinkGroup>
        </LinkWrapper>
        <SocialLinkWrapper>
          <SocialLink href="https://www.facebook.com/wearebo/" target="_blank">
            <Facebook />
          </SocialLink>
          <SocialLink href="https://www.instagram.com/wearebo/" target="_blank">
            <Instah />
          </SocialLink>
          <SocialLink
            href="https://www.linkedin.com/company/wearebo/"
            target="_blank"
          >
            <LinkedIn />
          </SocialLink>
        </SocialLinkWrapper>
        <Text>
          Bó is part of National Westminster Bank Plc (“NatWest”), registered in
          England and Wales (Registered Number 929027). Registered Office: 250
          Bishopsgate, London, EC2M 4AA. Authorised by the Prudential Regulation
          Authority and regulated by the Financial Conduct Authority and the
          Prudential Regulation Authority. NatWest is entered on the Financial
          Services Register and its Register number is 121878. The Financial
          Services Register can be accessed at{' '}
          <WhiteLink href="http://www.fca.org.uk/register" target="_blank">
            www.fca.org.uk/register
          </WhiteLink>
          . NatWest's registered VAT number is GB 243852752.
        </Text>
        <Text>
          Bó is protected by The Financial Services Compensation Scheme (FSCS).
          Read more{' '}
          <WhiteLink href="https://www.fscs.org.uk/" target="_blank">
            here
          </WhiteLink>
          .
        </Text>
        <Text>
          * If you have accounts with Bó and NatWest, the maximum you would be
          able to claim across all accounts combined is £85,000.
        </Text>
        <Text>
          Photo credit: All our lovely shots come from{' '}
          <WhiteLink href="https://unsplash.com/" target="_blank">
            unsplash.com.
          </WhiteLink>{' '}
          Thanks guys!
        </Text>
      </Content>
    </Wrapper>
  );
};

export default Footer;
