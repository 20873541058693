export const paths = {
  home: '/',
  contact: '/contact/',
  privacyFull: '/privacy-policy/full/',
  cookies: '/cookie-policy/',
  faqs: '/faq/',
  fscs: '/fscs/',
  terms: '/terms-and-conditions/',
  blog: '/blog/',
  websiteTerms: '/website-terms-and-conditions/',
  emergencyAccess: '/emergency-access/',
  emailSent: '/emergency-access/email-sent',
  emailExpired: '/emergency-access/email-expired',
  card: '/emergency-access/card',
};
