import React, { useState } from 'react';
import Header from '../../atoms/header';
import Footer from '../../atoms/footer';
import { GlobalStyles } from '../../styles/globalStyles';
import MobileNavbar from '../mobileNavbar';
import { Main } from './styles';
import { OneTrust } from '../../atoms/oneTrust/OneTrust';

export type LayoutProps = {
  children: any;
};

const Layout = ({ children }: LayoutProps) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const toggleDrawer = () => {
    if (!isDrawerVisible) {
      return setIsDrawerVisible(true);
    }
    setIsDrawerVisible(false);
  };

  return (
    <>
      <OneTrust />
      <GlobalStyles />
      <Header />
      <MobileNavbar toggleDrawer={toggleDrawer} isVisible={isDrawerVisible} />
      <Main>{children}</Main>
      <Footer />
    </>
  );
};

export default Layout;
