import { createGlobalStyle } from 'styled-components';
import { colors } from '../../styles/theme';

/**
 * Style overrides for oneTrust to
 * make it more Bó
 */

export const OneTrustStyles = createGlobalStyle`
  body #onetrust-consent-sdk #onetrust-banner-sdk {
    background-color: ${colors.darkGrey};

    #onetrust-policy-text{
      color: ${colors.white};
    }

    .close-icon {
      @media (max-width: 896px){
        margin-bottom: 10px;
      }

      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzUwcHgiIGhlaWdodD0iMzUwcHgiIHZpZXdCb3g9IjAgMCAzNTAgMzUwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MS4yICg4OTY1MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+Y29va2llc19jcm9zczwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSIxMC414oCdLWlQYWQtQWlyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTc3LjAwMDAwMCwgLTI5My4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPGcgaWQ9ImNvb2tpZXNfY3Jvc3MiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE2MC4wMDAwMDAsIDI3Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE5Mi4xMjEzMjAsIDE5MS42MjEzMjApIHJvdGF0ZSg0NS4wMDAwMDApIHRyYW5zbGF0ZSgtMTkyLjEyMTMyMCwgLTE5MS42MjEzMjApICIgeD0iLTM3Ljg3ODY3OTciIHk9IjE1MS4xMjEzMiIgd2lkdGg9IjQ2MCIgaGVpZ2h0PSI4MSIgcng9IjQwLjUiPjwvcmVjdD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE5Mi4wMDAwMDAsIDE5MS41MDAwMDApIHNjYWxlKC0xLCAxKSByb3RhdGUoNDUuMDAwMDAwKSB0cmFuc2xhdGUoLTE5Mi4wMDAwMDAsIC0xOTEuNTAwMDAwKSAiIHg9Ii0zOCIgeT0iMTUxIiB3aWR0aD0iNDYwIiBoZWlnaHQ9IjgxIiByeD0iNDAuNSI+PC9yZWN0PgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=');
    }

    .ot-sdk-container{
      padding: 16px;
      width: 100%;
      @media (min-width: 1024px){
        padding-left: 10vw;
        padding-right: 10vw;
      }      
    }

    #onetrust-policy{
      margin-left: 0;
      @media (max-width: 1024px){
          margin: 0;
      }
    }

    @media (max-width: 896px) and (min-width: 426px) {
      .onetrust-close-btn-ui.onetrust-lg {
        top: 24px;
        right: 16px;
      }
    }

    #onetrust-button-group {
      display: flex;      
      flex-wrap: wrap;
      margin-right: 0;
      margin-left: 0;
      justify-content: flex-start;
      display: flex;

      @media (min-width: 896px){
        justify-content: flex-end;
      }

      #onetrust-pc-btn-handler, #onetrust-accept-btn-handler{
        width: 100%;
        margin-right: 0;
        font-family: 'GilroySemiBold';
        @media (min-width: 1024px){
          margin-right: 1em;
          width: auto;
        }

        background-color: ${colors.brandYellow};
        border-color: ${colors.brandYellow};
        color: ${colors.darkGrey};
        &:hover, &:active, &:focus {
          opacity: 1
        }
      }
    }
  }
`;
