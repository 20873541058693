import { config as developmentConfig } from './development';
import { config as stagingConfig } from './staging';
import { config as productionConfig } from './production';
import { Config } from './model';

enum Environment {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

const allConfigs = {
  [Environment.DEVELOPMENT]: developmentConfig,
  [Environment.STAGING]: stagingConfig,
  [Environment.PRODUCTION]: productionConfig,
};

const setEnvironment = (): Config => {
  if (process.env.GATSBY_BUILD_ENVIRONMENT) {
    return allConfigs[process.env.GATSBY_BUILD_ENVIRONMENT];
  }
  return allConfigs[Environment.DEVELOPMENT];
};

export const config = setEnvironment();
