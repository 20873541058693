import styled from 'styled-components';
import { Link } from 'gatsby';
import { colors, fontSizes, media, dimensions } from '../../styles/theme';
import LogoSvg from '../../assets/svgs/logo.svg';

export const Wrapper = styled.div<{ isVisible: boolean }>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 6;
  display: none;
  background-color: ${colors.white};
  justify-content: space-between;
  height: ${dimensions.headerHeight};
  margin: auto;
  padding-left: 5vw;
  padding-right: 5vw;
  border-bottom: 0.5px solid ${colors.backgroundGrey};
  transition: 0.5s ease-in-out;
  transform: translateY(${props => (props.isVisible ? '0%' : '-100%')});
  ${media.desktop`
    display: flex;
  `}
`;

export const LinkWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const LogoWrapper = styled(Link)`
  width: 180px;
`;

export const Logo = styled(LogoSvg)<{ isSmallHeader: boolean }>`
  transition: 0.3s ease-in-out;
  margin-bottom: -70px;
  transform: rotate(5deg);
  height: 110px;
  width: 100px;

  ${props =>
    props.isSmallHeader &&
    `height: 80px; width: 80px; margin-bottom: 0; filter: none;`}

  @media (min-width: 1300px) {
    margin-bottom: -90px;
    height: 130px;
    width: 130px;

    ${props =>
      props.isSmallHeader &&
      `height: 80px; width: 80px; margin-bottom: 0; filter: none;`}
  }
`;

export const StyledLink = styled(Link)`
  position: relative;
  text-decoration: none;
  font-size: ${fontSizes.headerText};
  font-family: 'GilroySemiBold';
  color: ${colors.darkGrey};
  width: 100px;
  text-align: center;
  z-index: 2;
  border-bottom: 5px solid transparent;
  padding-bottom: 28px;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 5px;
    bottom: -5px;
    left: 0;
    background-color: ${colors.brandYellow};
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }
  &:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  &.active:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
`;
