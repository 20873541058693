import styled from 'styled-components';
import { colors, dimensions, spacing, media } from '../../styles/theme';
import LogoSvg from '../../assets/svgs/logo.svg';

export const Wrapper = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: ${spacing.base};
  padding-right: ${spacing.base};
  background-color: ${colors.white};
  border-bottom: 0.5px solid ${colors.backgroundGrey};
  height: ${dimensions.mobileHeaderHeight};
  ${media.desktop`
    display: none;
  `}
`;

export const Logo = styled(LogoSvg)`
  width: 50px;
  height: 50px;
  margin-top: ${spacing.quarter};
`;
