import { css } from 'styled-components';

export const colors = {
  lightText: '#A8A8A8',
  brandYellow: '#FFD700',
  hoverYellow: '#FFC600',
  secondaryColor: '#DDE7EE',
  signUpText: '#585858',
  white: '#FFFFFF',
  borderGrey: '#ECECEC',
  darkGrey: '#525257',
  backgroundGrey: '#F8F8F8',
  blue: '#328FD8',
  black: '#1B1B1B',
  red: '#FF5546',
  textBlue: '#0091EF',
  slate: '#F0F0F5',
};

export const fontFamily = 'Gilroy, Avenir, sans-serif';

export const fontSizes = {
  blogSmall: '11px',
  small: '12px',
  buttonMobile: '14px',
  mobileText: '16px',
  normal: '18px',
  headerText: '16px',
  subHeading: '24px',
  scrollHeading: '26px',
  link: '30px',
  subtitle: '32px',
  large: '40px',
  blogTitle: '42px',
  sixty: '60px',
  larger: '64px',
  title: '80px',
  gigantic: '200px',
};

export const dimensions = {
  maxSignUpWidth: '550px',
  maxWidth: '1600px',
  maxBlogWidth: '800px',
  headerHeight: '90px',
  mobileHeaderHeight: '70px',
  mobileFooterHeight: '50px',
  minRowHeight: '90vh',
  homeContentWidth: '700px',
  blogWrapperWidth: '1300px',
};

export const spacing = {
  quarter: '4px',
  half: '8px',
  borderRadius: '10px',
  ten: '10px',
  base: '16px',
  eighteen: '18px',
  twenty: '20px',
  thirty: '30px',
  double: '32px',
  forty: '40px',
  sixty: '60px',
  ninety: '90px',
};

export const sizes = {
  scroll: 1347,
  giant: 1170,
  ipadPro: 1025,
  desktop: 992,
  tablet: 768,
  phonePlus: 500,
  phone: 375,
  se: 320,
};

export const media = (Object.keys(sizes) as Array<keyof typeof sizes>).reduce(
  (acc, label) => {
    acc[label] = (first: any, ...interpolations: any[]) => css`
      @media (min-width: ${sizes[label]}px) {
        ${css(first, ...interpolations)}
      }
    `;

    return acc;
  },
  {} as { [key in keyof typeof sizes]: any }
);
