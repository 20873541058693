import { createGlobalStyle } from 'styled-components';
import { colors, fontFamily, spacing, fontSizes, media } from './theme';
import GilroyRegular from '../assets/fonts/Gilroy-Regular.otf';
import GilroySemiBold from '../assets/fonts/Gilroy-SemiBold.otf';
import GilroyBold from '../assets/fonts/Gilroy-Bold.otf';
import Bressay from '../assets/fonts/Bressay.ttf';
import BressayBold from '../assets/fonts/Bressay-Bold.ttf';
import BressayBoldItalic from '../assets/fonts/Bressay-BoldItalic.ttf';
import JustAnotherHand from '../assets/fonts/JustAnotherHand-Regular.ttf';

export const GlobalStyles = createGlobalStyle`
  html {
    background-color: ${colors.brandYellow};
  }

  body {
    background-color: ${colors.white};
  }

  html, body {
    padding: 0;
    margin: 0;
    font-family: ${fontFamily}; 
  }

  * {
    box-sizing: border-box;
  }

  main {
    display: block;
    padding-top: 110px;
    ${media.desktop`
      padding-top: 0px;
    `}
  }

  h1 {
    font-family: 'GilroyBold', Avenir, sans-serif;
    line-height: 80%;
    font-size: ${fontSizes.gigantic};
    color: ${colors.darkGrey};
  }

  h2 {
    font-family: 'BressayBoldItalic', Georgia, serif;
    line-height: 120%;
    color: ${colors.darkGrey};
    margin-bottom: ${spacing.base}; 
    font-size: 38px;
    font-style:italic;
  }

  h3,h4,h5 {
    margin: 0;
    padding: 0;
    color: ${colors.darkGrey};
    line-height: 150%;
    margin-bottom: 10px;
    font-family: ${fontFamily};
  }

  p, li, td, i {
    color: ${colors.darkGrey};
    line-height: 150%;
    margin-bottom: ${spacing.half};  
    font-size: ${fontSizes.normal};
    font-family: 'Gilroy';
  }
  
  table {
    border-collapse: collapse;
    margin-top: 20px;
  }

  strong {
    font-family: 'GilroyBold';
    color: ${colors.darkGrey};
    line-height: 150%;
    margin-bottom: ${spacing.half};  
    font-size: ${fontSizes.normal};
  }

  a {
    color: ${colors.darkGrey};
  }

  b {
    font-family: 'Bressay';
    font-weight: 900;
    color: ${colors.darkGrey};
  }

  button {
    font-size: ${fontSizes.mobileText};
    font-family: 'GilroySemiBold';
    color: ${colors.darkGrey};
  }

  img {
    max-width: 100%;
  }

  *:focus {
    outline:none;
  }

  p::selection, h2::selection, li::selection, td::selection, a::selection, h2::selection, h3::selection, h4::selection, h5::selection, b::selection, i::selection {
    background: ${colors.brandYellow}; /* WebKit/Blink Browsers */
  }
  p::-moz-selection, h2::-moz-selection, li::-moz-selection, td::-moz-selection, a::-moz-selection, h2::-moz-selection, h3::-moz-selection, h4::-moz-selection, h5::-moz-selection, b::-moz-selection, i::-moz-selection {
    background: ${colors.brandYellow}; /* Gecko Browsers */
  }
 
  @font-face {
    font-family: 'Gilroy';
    src: url(${GilroyRegular}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'GilroySemiBold';
    src: url(${GilroySemiBold}) format('opentype');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'GilroyBold';
    src: url(${GilroyBold}) format('opentype');
    font-weight: 800;
    font-style: normal;
  }
  @font-face {
    font-family: 'Bressay';
    src: url(${Bressay}) format('opentype');
    font-style: normal;
  }
  @font-face {
    font-family: 'BressayBoldItalic';
    src: url(${BressayBoldItalic}) format('opentype');
    font-style: normal;
  }
  @font-face {
    font-family: 'BressayBold';
    src: url(${BressayBold}) format('opentype');
    font-style: normal;
  }
  @font-face {
    font-family: 'JustAnotherHand';
    src: url(${JustAnotherHand}) format('opentype');
    font-style: normal;
  }

`;
