import React from 'react';
import { paths } from '../../const/paths';
import {
  DrawerWrapper,
  Content,
  StyledLink,
  Button,
  SocialMediaWrapper,
  SocialLink,
} from './styles';
import Hamburger from '../../assets/svgs/hamburger.svg';
import Close from '../../assets/svgs/close.svg';
import Facebook from '../../assets/svgs/facebook.svg';
import Instah from '../../assets/svgs/instagram.svg';
import LinkedIn from '../../assets/svgs/linked-in.svg';

export type DrawerProps = {
  isVisible: boolean;
  toggleDrawer: () => void;
};

const Drawer = ({ isVisible, toggleDrawer }: DrawerProps) => {
  return (
    <>
      <DrawerWrapper isVisible={isVisible}>
        <Content>
          <StyledLink to={paths.home} onClick={toggleDrawer}>
            Home
          </StyledLink>
          <StyledLink to={paths.faqs} onClick={toggleDrawer}>
            FAQs
          </StyledLink>
          <StyledLink to={paths.blog} onClick={toggleDrawer}>
            Blog
          </StyledLink>
          <StyledLink to={paths.contact} onClick={toggleDrawer}>
            Contact us
          </StyledLink>
        </Content>
        <SocialMediaWrapper>
          <SocialLink href="https://www.facebook.com/wearebo/" target="_blank">
            <Facebook />
          </SocialLink>
          <SocialLink href="https://www.instagram.com/wearebo/" target="_blank">
            <Instah />
          </SocialLink>
          <SocialLink
            href="https://www.linkedin.com/company/wearebo/"
            target="_blank"
          >
            <LinkedIn />
          </SocialLink>
        </SocialMediaWrapper>
      </DrawerWrapper>
      <Button onClick={toggleDrawer}>
        {isVisible ? <Close /> : <Hamburger />}
      </Button>
    </>
  );
};

export default Drawer;
