import React from 'react';
import { Link } from 'gatsby';
import { paths } from '../../const/paths';
import Drawer from '../drawer';
import { Wrapper, Logo } from './styles';

export type MobileNavbarProps = {
  isVisible: boolean;
  toggleDrawer: () => void;
};

const MobileNavbar = (props: MobileNavbarProps) => {
  return (
    <Wrapper>
      <Link to={paths.home}>
        <Logo />
      </Link>
      <Drawer {...props} />
    </Wrapper>
  );
};

export default MobileNavbar;
